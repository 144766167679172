import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import rima from './partials/rima.png';
import eu from './partials/EU.png'

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer style={{backgroundColor:'#192e3a'}}
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <Logo />

          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav />
            <div className="footer-copyright">©<span style={{color:'#d6d6d6'}}> robohop</span>. <a href="https://google.com"></a> All rights reserved</div>
          </div>
          <div>
          <br/>
          <div style={{display:'flex',border:'2px solid #264354',padding:'20px',borderRadius:'20px',paddingBottom:'0px'}}>


          <img src={rima} style={{width:'100px',height:'fit-content',marginLeft:'-20px',marginTop:'-20px'}}/>
          <p style={{fontSize:'14px', width:'100%', textAlign:'justify'}}>This project has received funding from the European Union’s Horizon 2020 research and innovation programme within the framework
          of the RIMA – Robotics for Inspection and Maintenance Project funded under grant agreement No. 824990</p>
          <img src={eu} style={{width:'80px',height:'fit-content',marginLeft:'20px',marginTop:'px'}}/>
          </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
