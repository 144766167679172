import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'About ',

  };

  return (
    <section id='about'
      {...props}
      className={outerClasses} style={{paddingTop:'50px',backgroundColor:'#13676c'}}
    ><br/>

      <div className="container">
        <div className={innerClasses}>

          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
          <p style={{textAlign:'justify',paddingRight:'10%',paddingLeft:'10%',fontSize:'19px'}}>RoboHop is the first robotic crawler capable of delivering dry ultrasonic inspection
          in complex structures such as cargo tanks which require the robot to be able to traverse vertical surfaces.</p>
          <p style={{textAlign:'justify',paddingRight:'10%',paddingLeft:'10%',fontSize:'19px'}}>RoboHop is a novel robotic inspection platform designed with three
          core principles in mind:

          <ol>
          <br/>
  <li>Ability to transition between vertical surfaces so as to traverse complex geometries</li>
  <li>Ability to perform inspection without the need of prior surface cleaning.</li>
  <li>  Ultra-low cost of ownership.</li>
</ol>
<p>This is achieved by bringing together the necessary resources to design and develop
both the robot and the ultrasonic inspection tool entirely in-house rather than relying on commercial inspection tools.</p>
        </p>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
