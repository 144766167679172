import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav style={{cursor:'pointer'}}
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="#0">Contact</Link>
        </li>
        <li>
          <Link to="about" smooth={true} offset={50} duration={1500}>About</Link>
        </li>

      </ul>
    </nav>

  );
}

export default FooterNav;
