import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Contact() {

     return (
       <div class="container">
   <div class="row contact-container">
     <div class="col-lg-12">
       <div class="card card-shadow border-0 mb-4">
         <div class="row">
           <div class="col-lg-8">
             <div class="contact-box p-4">
               <h4 class="title" style={{textAlign:'center',color:'#241d25'}}>Contact Us</h4>
               <form>
                 <div class="row">
                   <div class="col-lg-6">
                     <div class="form-group mt-3">
                       <input class="form-control" type="text" placeholder="name"/>
                     </div>
                   </div>
                   <div class="col-lg-6">
                     <div class="form-group mt-3">
                       <input class="form-control" type="text" placeholder="email"/>
                     </div>
                   </div>


                   <div class="col-lg-12">
                     <div class="form-group mt-3">
                       <input class="form-control" type="text" placeholder="message"/>
                     </div>
                   </div>
                   <div id='submit' class="col-lg-12">
                     <button type="submit" id='button2' class="btn btn-danger-gradiant mt-3 mb-3 text-white border-0 py-2 px-3"><span> SUBMIT NOW <i class="ti-arrow-right"></i></span></button>
                   </div>
                 </div>
               </form>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
     );

 }

 export default Contact;
