import React, { useRef } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'



const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const form = useRef();

 const sendEmail = (e) => {
   e.preventDefault();

   emailjs.sendForm('service_3l9bnqs', 'template_b8vmjd9', form.current, 'user_bjfdktCgzdtC6Db51uXLN')
     .then((result) => {
         console.log(result.text);
         Swal.fire(
          '',
          'Your message sent!',
          'success'
        )
     }, (error) => {
         console.log(error.text);
         Swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Something went wrong!',
})
     });
 };


  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {

  };

  return (
    <section id='contact'
      {...props}
      className={outerClasses} style={{backgroundColor:'#13676c'}}
    >
    <div class="container">
<div class="row contact-container">
  <div class="col-lg-12">
    <div class="card card-shadow border-0 mb-4">
      <div class="row">
        <div class="col-lg-8">
          <div class="contact-box p-4">
            <h4 class="title" style={{textAlign:'center',color:'#192e3a'}}>Contact Us</h4>
            <form ref={form} onSubmit={sendEmail}>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mt-3">
                    <input class="form-control" name='name' type="text" placeholder="name" required/>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mt-3">
                    <input class="form-control" type="email" name='email' placeholder="email" required/>
                  </div>
                </div>


                <div class="col-lg-12">
                  <div class="form-group mt-3">
                    <textarea class="form-control" type="text" name='message' placeholder="message" required/>
                  </div>
                </div>
                <div id='submit' class="col-lg-12">
                  <button type="submit" id='button2' class="btn btn-danger-gradiant mt-3 mb-3 text-white border-0 py-2 px-3"><span> SUBMIT<i class="ti-arrow-right"></i></span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<br/><br/>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
