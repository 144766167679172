import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Solutions',

  };

  return (
    <section id='solutions'
      {...props}
      className={outerClasses}
    >
      <div className="container" >
        <div className={innerClasses}>

          <SectionHeader data={sectionHeader} className="center-content" />
          <p style={{textAlign:'justify',paddingRight:'5%',paddingLeft:'5%',fontSize:'19px'}}>RoboHop is designed and developed building both on lessons learnt from our own experience in automating inspection tasks in the shipping industry.
          We’ve seen first-hand what has worked and what has failed and have obtained a deep understanding of end-user’s needs.</p>
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Problem
                  </div>
                <h3 className="mt-0 mb-12" style={{fontSize:'24px'}}>
                  Health & Safety risks
                  </h3>
                <p className="m-0">
                  are high with Falling from Height and Enclosed Space accidents dominating death and injury causes in maritime
                  </p>
              </div>
              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Solution
                    </div>
                  <h3 className="mt-0 mb-12" style={{fontSize:'24px'}}>
                    Surface Hopping
                    </h3>
                  <p className="m-0">
                    Thanks to its tailored design based on magnetic wheels, RoboHop can transition between vertical surfaces without risking detachment.
                    This is a crucial feature for complex structures such as cargo tanks, unlike ship hulls, are rife with structural features.
                    </p>
                </div>
                </div>
            </div>




            <div className="split-item">
              <div className="split-item-image center-content-mobile reveal-from-bottom"  data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Problem
                  </div>
                <h3 className="mt-0 mb-12" style={{fontSize:'24px'}}>
                Structure complexity
                  </h3>
                <p className="m-0" >
                  increases vessel idle time which costs > €50,000 per day.
                  </p>
              </div>
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item" >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Solution
                  </div>
                <h3 className="mt-0 mb-12" style={{fontSize:'24px'}}>
                  Dry Inspection
                  </h3>
                <p className="m-0">
                RoboHop leverages the Electro Magnetic Acoustic Transducer (EMAT) technique, which generates the sound in the part inspected instead of the transducer.
                Because of this fundamentally different approach compared to conventional ultrasonics EMATs can perform dry inspection, i.e. they do not require couplants
                for transmitting sound.
                EMAT can inspect through coatings and are not affected by pollutants, oxidation or roughness.
                  </p>
              </div>
            </div>



            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Problem
                  </div>
                <h3 className="mt-0 mb-12" style={{fontSize:'24px'}}>
                  Cargo Contamination
                  </h3>
                <p className="m-0">
                  cases are on the rise, costing > €100,000 per incident.
                  </p>
              </div>
              <div id='third' className="split-item-image center-content-mobile reveal-from-bottom'" data-reveal-container=".split-item" style={{paddingRight:'-20px'}}>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Solution
                  </div>
                <h3 className="mt-0 mb-12" style={{fontSize:'24px'}}>
                  Low Cost
                  </h3>
                <p className="m-0">
                The entire system is designed and developed in-house avoiding the high mark-ups associated with Commercial Off-the-Shelf (COTS) ultrasonic
                inspection tools. This allows us to keep manufacturing costs at the absolute minimum and offer RoboHop at a price that will
                be an absolute bargain for service providers.
                  </p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
